<template>
  <div class="Site PageBox">
    <van-nav-bar
      fixed
      :border="false"
      :title="$t('common2[0]')"
      left-arrow
      @click-left="$router.go(-1)"
    />
    <div class="ScrollBox">
      <van-button color="#F4D34F" size="large" style="margin: 10px;width: 95%"  v-for="item in servicelist"
                  @click="$router.push(`/article/service/${item.id}`)"
      ><span style="color:#000">{{item.title}}</span>
      </van-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Service',
  components: {

  },
  props: {},
  data() {
    return {
      servicelist:[]
    }
  },
  computed: {

  },
  watch: {

  },
  created() {
    
  },
  mounted() {
    this.servicelist = this.InitData.serviceList;
  },
  activated() {

  },
  destroyed() {
    
  },
  methods: {
 
  }
}
</script>
<style scoped>
  .PageBox {
    color: #635327;
    background-color: #0F1A29;
  }

  .van-nav-bar {
    background-color: #0F1A29;
  }
  .van-nav-bar>>>.van-nav-bar__title{
    color:#cccccc;
    font-weight: bold;
  }
  .ScrollBox{
    background-color: #0F1A29;
  }
</style>